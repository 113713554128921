@import "glows";

$character-shadow-filter: drop-shadow(-2px 4px 8px #666);
$shadow-black-filter: drop-shadow(-2px 4px 8px black);

$drop-shadow-simple-black: 2px 4px 3px rgba(0, 0, 0, 0.3);
$drop-shadow-simple-white: 2px 4px 6px rgba(255, 255, 255, 0.3);

$text-shadow-simple-black: 2px 4px 3px rgba(0, 0, 0, 0.3);
$text-shadow-simple-white: 2px 4px 3px rgba(255, 255, 255, 0.3);

@mixin generateGradientBg() {
  background-color: rgba(#cddcdc, 0.85);
  background-image: radial-gradient(
      at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
  background-blend-mode: luminosity;
}

@mixin generateTextShadow() {
  color: white;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
    0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}
