@import "styles/variables";

.App {
  //background: url("./assets/bg2.png") no-repeat;
  //background-size: cover;
  height: 100%;

  overflow: hidden;
  font-family: "Creepster", serif;

  .Instructions {
    position: fixed;
    overflow: auto;
    z-index: 20;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 48px;
    color: white;
    background: rgba(20, 40, 40, 0.98);
    padding: 0 15%;

    p {
      font-family: "Arvo", sans-serif;
      font-size: 32px;

      @media (max-width: 420px) {
        font-size: 16px;
      }
    }

    h3 {
      margin-bottom: 12px;
      align-self: center;
    }
  }

  .Goo {
    position: absolute;
    z-index: 2;
    width: 100%;
    max-height: 200px;
    opacity: 0.5;

    svg {
      max-height: inherit;
      width: 50%;
    }
  }

  @media (max-width: 420px) {
    .Branding {
      img {
        max-width: 100%;
      }
    }
  }

  .Branding {
    position: absolute;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    right: 0;
    padding: 24px;
    z-index: 10;

    img {
      max-width: 400px;
      max-height: 130px;
      margin-top: -24px;
      margin-left: -24px;

      filter: drop-shadow($drop-shadow-simple-white);
      opacity: 0.9;
    }

    .Branding__welcome-msg {
      font-family: "Arvo", sans-serif;
      font-size: 36px;
      opacity: 0.87;
      text-shadow: $text-shadow-simple-black;
      color: white;

      margin-left: 0.5em;
      padding: 0.5em;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  .bg {
    position: absolute;
    z-index: 1;
    height: 100%;
  }
}
