button.Button {
  border: none;
  border-radius: 1em;
  padding: 0.5em 1em;
  font-size: 0.875em;
  background: #111111;
  color: white;
  cursor: pointer;
  font-family: inherit;
}
