@import "../../styles/variables";

.character-select {
  padding: 0;
  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 2;
  //background: radial-gradient(rgba(whitesmoke, 0.75), transparent);
  //background-image: linear-gradient(
  //  to right,
  //  rgba(#f83600, 0.75) 0%,
  //  rgba(#f9d423, 0.75) 100%
  //);
  @include generateGradientBg();

  .glow {
    color: #444;
    text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc,
      1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc,
      3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc,
      5px 6px 1px #eee, 7px 6px 1px #ccc;
  }

  .character-select-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 144px 144px 36px;
    position: relative;
    z-index: 5;

    font-size: 68px;
    @include generateTextShadow();

    @media (max-width: 420px) {
      margin: 3em 2em 0.5em;
      border-top: 1px solid #666;
      padding-top: 12px;
    }
  }

  .characters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem 2rem;

    .character {
      display: inline-block;

      img {
        max-height: 360px;
        cursor: pointer;
        transition: all 250ms ease-out, filter 225ms ease-out;
        filter: $character-shadow-filter;

        &:hover {
          filter: $glow-filter;
        }
      }
    }
  }
}
