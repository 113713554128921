@import "../../../styles/variables";

$candy-size: 150px;
$candy-starting-opacity: 0.001;
$candy-secondary-opacity: 0.5;

$transition-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
$transition-time-sm: 250ms;
$transition-time-lg: 1.4s;

.CandyGrid {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 2;

  .CandySpot {
    position: absolute;
    transition: all $transition-time-lg $transition-function;

    img {
      display: inline-block;
      cursor: pointer;
      max-height: $candy-size;
      max-width: $candy-size - 50px;

      transition: all $transition-time-sm $transition-function;
    }

    &:not(.CandySpot--collected) {
      &.CandySpot--secondary {
        img {
          transition: opacity $transition-time-lg $transition-function;
          opacity: $candy-secondary-opacity;
        }
      }
      img {
        opacity: $candy-starting-opacity;
        cursor: pointer;

        &:hover {
          opacity: 1;
          filter: $glow-filter;

          animation: pulse; /* referring directly to the animation's @keyframe declaration */
          animation-duration: 1.5s; /* don't forget to set a duration! */
        }
      }
    }

    &--collected {
      animation: rotateOut;
      animation-duration: $transition-time-lg/1.25;

      img {
        opacity: 0;
        transition: opacity $transition-time-lg $transition-function;
        filter: $glow-filter;
      }
    }
  }
}
