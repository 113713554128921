.CandyBucket {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 32px;
  left: 96px;
  width: 120px;
  height: 100px;

  background: rgba(5, 5, 5, 0.935);
  border-radius: 50%;
  z-index: 2;

  &__count,
  &__goal {
    font-family: "Arvo", sans-serif;
    color: white;
  }

  &__count {
    font-size: 64px;
  }

  &__goal {
    font-size: 24px;
  }

  &__goal-slash {
    opacity: 0.8;
    font-size: 32px;
    margin-right: 0.05em;
  }
}
