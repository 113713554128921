@import "../../styles/variables";

.goal-reached {
  @include generateGradientBg();

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;

  @include generateTextShadow();

  font-size: 24px;

  &__pic {
    width: 300px;
    max-width: 100%;
    margin-bottom: 5px;
    filter: $character-shadow-filter;
  }

  &__title {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  &__subtitle {
    font-family: "Arvo", sans-serif;
    font-size: 1em;
    margin-bottom: 1em;
    color: #222222;
    text-shadow: initial;

    margin-top: 0.5em;
    padding-top: 1em;
    border-top: 1px solid rgba(#111, 0.2);
  }

  &__form {
    text-align: center;
    width: 480px;
    max-width: 100%;
    font-family: "Arvo", sans-serif;

    input {
      background: rgba(255, 255, 255, 0.1);
      border: none;
      border-radius: 2em;
      font-size: 0.875em;
      height: auto;
      margin: 0;
      outline: 0;
      padding: 0.875em 1em;
      width: 100%;
      background-color: #e8eeef;
      color: #8a97a0;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
      margin-bottom: 1.25em;
      font-family: inherit;

      &::placeholder {
        opacity: 0.75;
      }
    }
  }
}
